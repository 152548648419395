import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    primary: '#4e4adb',
    focus: '#4e4adb',
    secondary_transparent: '#6965e6',
    border: '#cccccc',
    background_buttons: '#ccc',
    text: '#808080',
    text_defocused: '#666',
    text_light: 'rgb(247, 245, 252)',
    text_explore_button_hover: '#f7f5fc',
    background_floating: '#f5f5f5',
    background_floating_hover: 'rgba(247, 245, 252, 0.75)',
    background_overlay: '#fafafa',
    background_poi_navigation: '#f0f0f0',
    background_explore_button: '#f0f0f0',
    background_app: '#252525',
  },
  box_shadow: '1px 1px 6px 0 rgba(51, 51, 51, 0.5)',
}

export default theme
