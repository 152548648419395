import styled from 'styled-components'
import playIcon from '../assets/images/play.svg'
import { useProjectInformation } from '../hooks/useProjectInformation'
import { useStore } from '../state'
import LoadingProgressBar from './LoadingProgressBar'

function ProjectDescriptionModal() {
  const projectInformation = useProjectInformation()
  const isLoading = useStore((state) => state.modelLoadingActive)

  if (!projectInformation) {
    return null
  }

  return (
    <Description>
      <DescriptionHeader>
        <Title>{projectInformation?.name}</Title>
        {projectInformation?.description}
        {isLoading ? <LoadingProgressBar /> : <ExploreButton />}
      </DescriptionHeader>
      <DescriptionFooter>
        <Credits>
          <p>
            Claiborne Avenue Alliance
            <br />
            <a
              href={'http://www.claiborneavenuealliance.com'}
              target={'_blank'}
              rel="noreferrer"
            >
              www.claiborneavenuealliance.com
            </a>
            <br />
            claiborne.avenue.alliance@gmail.com
          </p>
          <p>
            Interactive experience developed by: Collective Form
            <br />
            <a
              href={'https://www.collectiveform.io'}
              target={'_blank'}
              rel="noreferrer"
            >
              www.collectiveform.io
            </a>
          </p>
        </Credits>
      </DescriptionFooter>
    </Description>
  )
}

function ExploreButton() {
  const toggleDescription = useStore(
    (state) => state.api.toggleProjectDescription
  )

  return (
    <Button onClick={toggleDescription}>
      Explore
      <Play>
        <PlayIcon src={playIcon}></PlayIcon>
      </Play>
    </Button>
  )
}

const Description = styled.div`
  position: absolute;
  text-align: left;
  max-width: 540px;
  margin-right: auto;
  margin-left: auto;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background_poi_navigation};
  top: 120px;

  @media screen and (max-width: 479px) {
    width: 94%;
    overflow-y: auto;
    top: 12px;
  }
`

const DescriptionHeader = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 15px 12px 12px;
  border-radius: 12px 12px 0 0;
  font-size: 15pt;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.background_overlay}; ;
`

const Title = styled.div`
  width: 100%;
  font-size: 18pt;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.primary};
`

const Button = styled.button`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.background_explore_button};
  color: ${(props) => props.theme.colors.primary};
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 9px 15px;
  margin-top: 20px;

  :hover {
    color: ${(props) => props.theme.colors.text_explore_button_hover};
    background-color: ${(props) => props.theme.colors.focus};
    cursor: pointer;
    box-shadow: ${(props) => props.theme.box_shadow};
    border-color: ${(props) => props.theme.colors.focus};
  }
`

const Play = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: -9px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.secondary_transparent};
  box-shadow: ${(props) => props.theme.box_shadow};
`

const PlayIcon = styled.img`
  width: 12px;
  margin-top: 7px;
  margin-left: 3px;
`

const DescriptionFooter = styled.div`
  padding: 15px 15px 21px;
  color: ${(props) => props.theme.colors.text_defocused};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
`

const Credits = styled.div`
  margin-top: 12px;
  color: ${(props) => props.theme.colors.text_defocused};
`

export default ProjectDescriptionModal
