import React from 'react'
import { Html } from '@react-three/drei'
import { useStore } from '../state'
import { Poi } from '../services/projectService'
import styled, { css, ThemeProvider } from 'styled-components'
import plusIcon from '../assets/images/plus.svg'
import { AnalyticsEvent, logAnalyticsEvent } from '../services/firebase'
import theme from '../theme'

export type PoiMarkerProps = {
  poi: Poi
}

// TODO: Refactor out a common Button component?
type ContainerProps = {
  selected?: boolean
}

const Container = styled.div<ContainerProps>`
  padding: 4px 6px 0px 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  font-size: 15px;
  height: 34px;
  text-align: left;
  user-select: none;

  background-color: ${(props) => props.theme.colors.background_floating};
  border-color: ${(props) => props.theme.colors.border};
  color: ${(props) => props.theme.colors.primary};

  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.focus};
      border-color: ${(props) => props.theme.colors.focus};
      color: ${(props) => props.theme.colors.text_light};
      box-shadow: ${(props) => props.theme.box_shadow};
    `};

  :hover {
    border-color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
`

const Marker = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.secondary_transparent};
  text-align: center;
`

const Plus = styled.img`
  width: 20px;
  margin-top: 5px;
`

const Name = styled.div`
  margin-right: 9px;
  margin-left: 39px;
  font-size: 16px;
  line-height: 31px;
  display: inline-block;
  flex-direction: row;
  align-items: center;
`

export function PoiMarker({ poi }: PoiMarkerProps) {
  const selectPoi = useStore((state) => state.api.selectPoi)
  const selectedPoiId = useStore((state) => state.selectedPoiId)
  const { poiDescriptionOn, togglePoiDescription } = useStore(
    (state) => state.api
  )

  const isSelected = selectedPoiId === poi.id

  const markerPosition = {
    x: poi.markerPosition.x,
    y: poi.markerPosition.height,
    z: poi.markerPosition.y,
  }

  const selectThisPoi = () => {
    if (isSelected) {
      togglePoiDescription()
    } else {
      poiDescriptionOn()
    }
    selectPoi(poi.id)
    logAnalyticsEvent(AnalyticsEvent.SelectPoi, {
      poi_name: poi.name,
      poi_id: poi.id,
    })
  }

  // We need to wrap any styled components within a Drei Html tag with a
  // ThemeProvider in order to access styled components theming
  return (
    <mesh position={[markerPosition.x, markerPosition.y, markerPosition.z]}>
      <Html style={{ width: 'max-content' }}>
        <ThemeProvider theme={theme}>
          <Container onClick={selectThisPoi} selected={isSelected}>
            <Marker>
              <Plus src={plusIcon} alt={'+'} />
            </Marker>
            <Name>{poi.name}</Name>
          </Container>
        </ThemeProvider>
      </Html>
    </mesh>
  )
}
